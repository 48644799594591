.landing-header {
  background-color: white;
  width: 100%;

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    position: relative;
  }

  &__title {
    flex: 1 0 100%;
    margin-bottom: 6px;

    @media (min-width: 640px) {
      flex: 0 0 auto;
      margin-bottom: 0;
    }
  }

  &__navigation {
    flex: 1 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    height: 3rem;
    padding-top: 1rem;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      background-color: #dce0e3;
    }

    @media (min-width: 640px) {
      flex: 1 1 auto;
      height: auto;
      padding-top: 0;

      &::before {
        display: none;
      }
    }
  }

  &__buttons {
    flex: 1 0 100%;
    display: none;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
    margin-top: 1rem;
    position: relative;

    @media (min-width: 640px) {
      display: flex;
      flex: 0 0 auto;
      margin-top: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      background-color: #dce0e3;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }
}

@media (min-width: 640px) {
  .landing-header__container {
    flex-wrap: nowrap;
    align-items: center;
  }

  .landing-header__buttons {
    &::before {
      opacity: 0;
    }
  }

  @media (max-width: 1024px) {
    .landing-header__container {
      flex-wrap: wrap;
    }

    .landing-header__title,
    .landing-header__navigation {
      margin-bottom: 8px;
    }

    .landing-header__buttons {
      flex-basis: 100%;
      justify-content: center;
      padding-top: 28px;

      &::before {
        opacity: 1;
        top: 0;
      }
    }

    .landing-header__navigation {
      padding-bottom: 0;
    }
  }
}
